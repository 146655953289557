import {
	getLocalUser,
	getUser,
	getUserBusiness,
	setProductSeller,
} from "config/user";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNewMessage, setUpdateMessage } from "redux/container/bazAiSlice";
import { ReactMic } from "react-mic";
import moment from "moment";
import AudioPlayer from "./AudioPlayer";
import BazAiApi from "services/BazAi";
import { Link, useNavigate } from "react-router-dom";
import { addCartFormate, productAddToCart } from "helpers/shoppingCart";
import Orders from "services/Orders";
import User from "services/User";
import { useDropzone } from "react-dropzone";
import { IonLoading, IonSpinner } from "@ionic/react";
import QtyController from "./QtyController";
import { toast } from "react-toastify";
import { setLocation } from "redux/container/userSlice";

export default function BazAi({ name = "name" }) {
	const dispatch = useDispatch();
	const messageData = useSelector((state) => state?.bazAi?.data);
	const user = getUser() ? getUser() : getLocalUser()?.user;
	const [ripple, setRipple] = useState(false);
	const [audioPlayer, setAudioPlayer] = useState(false);
	const [timer, setTimer] = useState(0);
	const [timerIntervalId, setTimerIntervalId] = useState();
	const [selectedProduct, setSelectedProduct] = useState();
	const [files, setFiles] = useState([]);
	let [imageUrl, setImageUrl] = useState("");
	let [loading, setLoading] = useState(false);
	const [value, setValue] = useState(null);
	const navigate = useNavigate();
	const [comment, setComment] = useState("");

	useEffect(() => {
		return async () => {
			// Make sure to revoke the data uris to avoid memory leaks
			files.forEach((file) => URL.revokeObjectURL(file.preview));
		};
	}, [name, files]);

	let data = [
		{
			cardType: "infoCard",
			id: 1,
			user_name: "Bot",
			receiver: true,
			message: `Hi, ${user?.full_name ? user?.full_name : user.DisplayName}`,
			created_at: moment().format("LLLL"),
			avatar: "assets/images/bot-s.png",
			type: "initial",
			loading: false,
		},
	];
	useEffect(() => {
		if (!messageData.length) dispatch(setNewMessage(data));
	}, []);

	const startTimer = () => {
		setTimer(0);
		const intervalId = setInterval(() => {
			setTimer((prevTimer) => prevTimer + 1);
		}, 1000);
		return intervalId;
	};

	const stopTimer = (intervalId) => {
		clearInterval(intervalId);
	};

	const handleTouchStart = () => {
		setRipple(true);
		const intervalId = startTimer();
		setTimerIntervalId(intervalId);
	};

	const handleTouchEnd = () => {
		setRipple(false);
		stopTimer(timerIntervalId);
		onStop();
		setTimer(0);
	};

	const onStop = async (recordedBlob) => {
		setSelectedProduct({});
		if (recordedBlob) {
			let url = URL.createObjectURL(recordedBlob?.blob);
			let data = {
				audioBlob: recordedBlob?.blob,
				audioUrl: url,
				type: "audio",
				loading: true,
			};
			newSenderItemHandler(data);
			let formData = new FormData();
			formData.append("audio_file", recordedBlob?.blob);
			const response = await BazAiApi.searchProduct(formData);
			if (response.success) {
				let formattedSuppliers = Object.entries(
					response?.order_detail?.supplier_comparison
				).map(([supplier, price]) => {
					return { supplier, price };
				});
				updateProductDataHandler(
					{
						id: messageData?.length + 2,
						cardType: "analysisCard",
						productQty: response?.order_detail,
						productInitQty: response?.order_detail?.quantity
							? response?.order_detail?.quantity
							: response?.results?.minimum_order_quantity,
						product: response?.results,
						type: "success",
						corns: response?.order_detail?.product_message,
						suppliers: formattedSuppliers,
					},
					{
						id: messageData?.length + 3,
						cardType: "productCard",
						productQty: response?.order_detail,
						productInitQty: response?.order_detail?.quantity
							? response?.order_detail?.quantity
							: response?.results?.minimum_order_quantity,
						product: response?.results,
						message: `Create Order \nSupplier: ${
							response?.results?.seller_business_name
						} \n${response?.results?.title} \n${
							response?.results?.country?.currency_symbol_native
						}${response?.results?.procure_price}x${
							response?.order_detail?.quantity
								? response?.order_detail?.quantity
								: response?.results?.minimum_order_quantity
						} = ${response?.results?.country?.currency_symbol_native}${
							response?.order_detail?.quantity
								? Number(
										response?.results?.procure_price *
											response?.order_detail?.quantity
								  )
								: Number(
										response?.results?.procure_price *
											response?.results?.minimum_order_quantity
								  )
						}\nTotal = ${
							response?.results?.country?.currency_symbol_native
						}${
							response?.order_detail?.quantity
								? Number(
										response?.results?.procure_price *
											response?.order_detail?.quantity
								  )
								: Number(
										response?.results?.procure_price *
											response?.results?.minimum_order_quantity
								  )
						}`,
						suppliers: formattedSuppliers,
						type: "success",
						corns: response?.order_detail?.product_message,
					}
				);
				setSelectedProduct(response?.results);
			} else {
				updateLastDataHandler({
					message: `Sorry, i couldn't find your products to create order. 😭\nI am baby and still learning😋`,
					type: "notFound",
					loading: false,
				});
			}
			if (response.status === 404) {
				updateLastDataHandler({
					message: `Sorry, i couldn't find your products to create order. 😭\nI am baby and still learning😋`,
					type: "notFound",
					loading: false,
				});
			}
		}
	};
	const updateLastDataHandler = (item) => {
		let data = {
			id: messageData?.length,
			user_name: "Bot",
			receiver: true,
			created_at: moment().format("LLLL"),
			avatar: "assets/images/bot-s.png",
			loading: false,
			cardType: "infoCard",
			...item,
		};
		dispatch(setUpdateMessage(data));
	};

	const updateProductDataHandler = (analysis, product) => {
		let data = [
			{
				id: messageData?.length + 1,
				user_name: "Bot",
				receiver: true,
				created_at: moment().format("LLLL"),
				avatar: "assets/images/bot-s.png",
				loading: false,
				cardType: "infoCard",
				...analysis,
			},
			{
				id: messageData?.length + 2,
				user_name: "Bot",
				receiver: true,
				created_at: moment().format("LLLL"),
				avatar: "assets/images/bot-s.png",
				loading: false,
				cardType: "infoCard",
				...product,
			},
		];

		dispatch(setUpdateMessage(data));
	};

	const updateBeforeLastDataHandler = (item) => {
		let data = {
			...item,
		};
		dispatch(setUpdateMessage(data));
	};

	const newSenderItemHandler = (extData) => {
		let data = [
			{
				id: messageData?.length + 1,
				user_name: user?.DisplayName,
				receiver: false,
				created_at: moment().format("LLLL"),
				avatar: user?.profile_image,
				...extData,
				loading: extData?.type === "image" ? true : false,
			},
			{
				id: messageData?.length + 2,
				user_name: "Bot",
				receiver: true,
				created_at: moment().format("LLLL"),
				avatar: "assets/images/bot-s.png",
				loading: true,
				type: "initial",
				cardType: "infoCard",
			},
		];
		dispatch(setNewMessage(data));
	};

	const createOrderRequest = async () => {
		newSenderItemHandler({ type: "accept" });
		let product = addCartFormate(
			messageData[messageData.length - 1]?.product
		);
		const data = {
			quantity: messageData[messageData.length - 1]?.productInitQty ?? 0,
			buyer: getUserBusiness().id,
			order_items: [product],
			price_adjustment: 0,
			seller:
				messageData[messageData.length - 1]?.product?.seller_business_pg_id,
			delivery_date: moment(new Date()).add(1, "days"),
			order_origin: "Ekkbaz Seller",
		};
		const response = await Orders.createOrderForBot(data);
		if (response.status === 200 || response.success) {
			updateLastDataHandler({
				message: `🎉 Thanks for Ordering 🎉\nYour Order has been placed.\nOrder No:  ${response?.data?.order_id}`,
				type: "orderSuccess",
				orderId: response?.data?._id,
			});
		} else if (response.status === 501) {
			updateLastDataHandler({
				message: `Sorry, I couldn't create this Order`,
				type: "initial",
				orderId: response?.data?._id,
			});
		} else {
			if (response.status === 404) {
				updateLastDataHandler({
					message: `This seller default Payment not set yet! please set default payment`,
					type: "initial",
					orderId: response?.data?._id,
				});
			} else {
				updateLastDataHandler({
					message: `Sorry, i couldn't find your products to create order. 😭\nI am baby and still learning😋`,
					type: "initial",
				});
			}
		}
	};
	const senderDataViewer = (data) => {
		if (data.type === "audio") {
			return (
				<div
					className='audio-player bg-success rounded-[20px] px-2 py-2 flex items-center'
					style={{
						borderBottomRightRadius: 0,
					}}>
					<AudioPlayer
						data={data}
						setAudioPlayer={setAudioPlayer}
						audioPlayer={audioPlayer}
					/>
				</div>
			);
		} else if (data.type === "accept") {
			return (
				<div
					className='audio-player bg-success rounded-[15px] px-3 py-3  flex items-center'
					style={{
						borderBottomRightRadius: 0,
					}}>
					<h3 className='text-white font-bold text-15'>Accept</h3>
				</div>
			);
		} else if (data.type === "cancel") {
			return (
				<div
					className='audio-player rounded-[15px] px-3 py-3  flex items-center'
					style={{
						background: "#FF564C",
						borderBottomRightRadius: 0,
					}}>
					<h3 className='text-white font-bold text-15'>Cancel</h3>
				</div>
			);
		} else if (data.type === "image") {
			return (
				<div
					className=' px-3 py-3  flex items-center w-[250px] mb-1 bg-white min-h-[150px]'
					style={{
						borderRadius: "20px 20px 0px 20px",
						boxShadow: "rgba(0, 0, 0, 0.07) 0px 2px 30px 0px",
					}}>
					{data?.loading ? (
						<div className='flex items-center justify-center h-[156px] m-auto'>
							{" "}
							<IonSpinner name='crescent'></IonSpinner>
						</div>
					) : (
						<img
							src={data?.imageUrl}
							className='rounded-[10px] m-auto image-sizer'
							alt='images'
						/>
					)}
				</div>
			);
		}
	};

	const formatTime = (seconds) => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		return `${String(minutes).padStart(1, "0")}:${String(
			remainingSeconds
		).padStart(2, "0")}`;
	};

	const cancelHandler = () => {
		newSenderItemHandler({ type: "cancel" });
		updateLastDataHandler({
			message: `Canceled! you can find product again.`,
			type: "cancel",
		});
	};

	const receiverDataViewer = (data) => {
		if (data?.cardType === "infoCard") {
			return (
				<div>
					{data.loading ? (
						<div className='loader'></div>
					) : (
						<p
							className='font-semibold text-15 w-[265px] bg-white text-black-700 p-3 mb-[5px]'
							style={{
								borderRadius: "20px 20px 20px 0",
								boxShadow: "0px 2px 30px 0px rgba(0, 0, 0, 0.07)",
							}}>
							<span className='block'>
								{data?.message.split("\n").map((line, index) => (
									<React.Fragment key={index}>
										{line}
										<br />
									</React.Fragment>
								))}
							</span>
						</p>
					)}
				</div>
			);
		} else if (data?.cardType === "analysisCard") {
			console.log(data?.productQty);
			return (
				<div>
					{data.loading ? (
						<div className='loader'></div>
					) : (
						<p
							className='font-semibold text-15 w-[265px] bg-white text-black-700 p-3 mb-[5px]'
							style={{
								borderRadius: "20px 20px 20px 0",
								boxShadow: "0px 2px 30px 0px rgba(0, 0, 0, 0.07)",
							}}>
							{data?.corns?.length && (
								<div className='mb-3'>
									<span
										className='block font-bold pb-1'
										style={{ color: "#808080" }}>
										Supplier Price Analysis:
									</span>
									<span style={{ color: "#808080" }}>
										{data?.suppliers?.map((item, index) => (
											<React.Fragment key={index}>
												{`${index + 1})${item?.supplier}: ${
													data?.product?.country
														?.currency_symbol_native
												}${item?.price}`}
												<br />
											</React.Fragment>
										))}
									</span>
								</div>
							)}
							{data?.corns?.length && (
								<>
									<span
										className='block font-bold pb-1'
										style={{ color: "#808080" }}>
										Risk Analysis:
									</span>
									<span style={{ color: "#808080" }}>
										{data?.corns?.map((line, index) => (
											<React.Fragment key={index}>
												{`${index + 1})${line}`}
												<br />
											</React.Fragment>
										))}
									</span>
								</>
							)}
						</p>
					)}
				</div>
			);
		} else if (data?.cardType === "productCard") {
			return (
				<div>
					{data.loading ? (
						<div className='loader'></div>
					) : (
						<p
							className='font-semibold text-15 w-[265px] bg-white text-black-700 p-3 mb-[5px]'
							style={{
								borderRadius: "20px 20px 20px 0",
								boxShadow: "0px 2px 30px 0px rgba(0, 0, 0, 0.07)",
							}}>
							<span className='mb-3 block'>
								{data?.message.split("\n").map((line, index) => (
									<React.Fragment key={index}>
										{line}
										<br />
									</React.Fragment>
								))}
							</span>
							{data?.corns?.length && (
								<div className='pb-3'>
									<QtyController
										data={data}
										item={data?.product}
										initQty={data?.productInitQty}
										updateLastDataHandler={updateLastDataHandler}
									/>
								</div>
							)}
						</p>
					)}
				</div>
			);
		}
	};

	const getSearchHandler = async (url) => {
		let formData = { image_url: url };
		const response = await BazAiApi.searchProduct(formData);
		if (response.success) {
			let formattedSuppliers = Object.entries(
				response?.order_detail?.supplier_comparison
			).map(([supplier, price]) => {
				return { supplier, price };
			});
			updateProductDataHandler(
				{
					id: messageData?.length + 2,
					cardType: "analysisCard",
					productQty: response?.order_detail,
					productInitQty: response?.order_detail?.quantity
						? response?.order_detail?.quantity
						: response?.results?.minimum_order_quantity,
					product: response?.results,
					type: "success",
					corns: response?.order_detail?.product_message,
					suppliers: formattedSuppliers,
				},
				{
					id: messageData?.length + 3,
					cardType: "productCard",
					productQty: response?.order_detail,
					productInitQty: response?.order_detail?.quantity
						? response?.order_detail?.quantity
						: response?.results?.minimum_order_quantity,
					product: response?.results,
					message: `Create Order \nSupplier: ${
						response?.results?.seller_business_name
					} \n${response?.results?.title} \n${
						response?.results?.country?.currency_symbol_native
					}${response?.results?.procure_price}x${
						response?.order_detail?.quantity
							? response?.order_detail?.quantity
							: response?.results?.minimum_order_quantity
					} = ${response?.results?.country?.currency_symbol_native}${
						response?.order_detail?.quantity
							? Number(
									response?.results?.procure_price *
										response?.order_detail?.quantity
							  )
							: Number(
									response?.results?.procure_price *
										response?.results?.minimum_order_quantity
							  )
					}\nTotal = ${
						response?.results?.country?.currency_symbol_native
					}${
						response?.order_detail?.quantity
							? Number(
									response?.results?.procure_price *
										response?.order_detail?.quantity
							  )
							: Number(
									response?.results?.procure_price *
										response?.results?.minimum_order_quantity
							  )
					}`,
					suppliers: formattedSuppliers,
					type: "success",
					corns: response?.order_detail?.product_message,
				}
			);
		} else {
			updateLastDataHandler({
				message: `Sorry, i couldn't find your products to create order. 😭\nI am baby and still learning😋`,
				type: "notFound",
				loading: false,
			});
		}
		if (response.status === 404) {
			updateLastDataHandler({
				message: `Sorry, i couldn't find your products to create order. 😭\nI am baby and still learning😋`,
				type: "notFound",
				loading: false,
			});
		}
	};

	useEffect(() => {
		let formData = new FormData();
		formData.append("key", files[0]);
		if (files.length !== 0) {
			setLoading(true);
			let data = {
				audioBlob: files[0],
				imageUrl: files[0]?.preview,
				type: "image",
			};
			newSenderItemHandler(data);
			let response = User.toBucketImage(formData);
			response.then((res) => {
				setLoading(false);
				if (res.status === 201) {
					setImageUrl(res.results[0]);
					// let data = {
					// 	audioBlob: files[0],
					// 	imageUrl: res.results[0],
					// 	type: "image",
					// };
					// newSenderItemHandler(data);
					updateBeforeLastDataHandler({
						imageUrl: res.results[0],
						loading: false,
						updateImage: true,
					});
					getSearchHandler(res.results[0]);
				}

				setValue(name, res.results[0]);
			});
		}
	}, [files, name]);

	const { getRootProps, getInputProps } = useDropzone({
		// accept: "image/jpeg, image/png, image/jpg",
		onDrop: (acceptedFiles) => {
			if (
				acceptedFiles[0].type === "image/jpeg" ||
				acceptedFiles[0].type === "image/png" ||
				acceptedFiles[0].type === "image/jpeg"
			) {
				setFiles(
					acceptedFiles.map((file) =>
						Object.assign(file, {
							preview: URL.createObjectURL(file),
						})
					)
				);
			}
		},
	});
	function alertMs(message) {
		navigate("/create-order-ai", { replace: true });
	}

	return (
		<div className='pt-14'>
			<div className='header pt-5 pb-[50px]'>
				<img src='assets/images/bot-m.png' className='m-auto' alt='bot' />
			</div>
			<div className='chat-body px-4 pb-[150px] bg-F2F3F7'>
				{messageData?.map((el, i) => {
					return (
						<div key={i}>
							<div className='receiver mb-[20px]'>
								{el.receiver ? (
									<div>
										<div className='flex'>
											<div className='mr-4 self-end'>
												<img
													src={el?.avatar}
													className='m-auto w-[26px] h-[26px] rounded-full'
													alt='bot'
												/>
											</div>
											{receiverDataViewer(el)}
										</div>
										<div className=' ml-10 w-[265px] flex justify-between'>
											<p className='text-black-333 text-12 font-bold'>
												{moment(el?.created_at).format("h:mm A")}
											</p>
											{el.type === "success" &&
											el.cardType !== "analysisCard" ? (
												<span
													// to={`/product-detail/${el?.product?.id}/${el?.product?.type}`}
													to={`!#`}
													onClick={() => {
														if (el.productInitQty) {
															if (navigator.geolocation) {
																let location =
																	navigator.geolocation;
																if (
																	location &&
																	Object.keys(location)
																		.length === 0
																) {
																	navigator.geolocation.getCurrentPosition(
																		(p) => {
																			dispatch(
																				setLocation({
																					type: "Point",
																					coordinates: [
																						p.coords
																							.longitude,
																						p.coords
																							.latitude,
																					],
																				})
																			);
																		}
																	);
																}
															} else {
																toast.er(
																	"Geolocation is not supported by this browser."
																);
															}
															localStorage.removeItem("cart");
															// let status = shoppingCart.addToCart(product, qty);
															let status = productAddToCart(
																el?.product,
																el?.product
																	?.minimum_order_quantity,
																el?.productInitQty
															);
															if (status) {
																setProductSeller(el?.product);
																alertMs(
																	"Product Add To Basket"
																);
															} else {
																alertMs(
																	"Product Already Added To Basket"
																);
															}
														} else {
															toast.warning(
																"Please increase the quantity"
															);
														}
													}}
													className='text-12 font-semibold underline text-delivered'>
													Go Details
												</span>
											) : undefined}
											{el.type === "orderSuccess" ? (
												<Link
													to={`/order-details/${el?.orderId}/buyer`}
													className='text-12 font-semibold underline text-delivered'>
													Go Details
												</Link>
											) : undefined}
										</div>
									</div>
								) : (
									<div>
										<div className='flex justify-end'>
											<div className='mr-4'>
												{senderDataViewer(el)}
											</div>
											<div className='self-end'>
												<img
													src={el?.avatar}
													className='m-auto w-[26px] h-[26px] rounded-full'
													alt='bot'
												/>
											</div>
										</div>
										<p className='text-black-333 text-12 font-bold text-right mr-10'>
											{moment(el?.created_at).format("h:mm A")}
										</p>
									</div>
								)}
							</div>
						</div>
					);
				})}
				<div className='sender'></div>
			</div>
			<div
				className='chat-footer w-full fixed sm:bottom-[0px] md:bottom-[20px]'
				style={{
					background: "#f2f3f7",
					boxShadow: "0 -4px 5px rgba(0, 0, 0, .05)",
				}}>
				<div className='hidden'>
					<ReactMic
						record={ripple}
						className='sound-wave'
						onStop={onStop}
						strokeColor='#000000'
						backgroundColor='#FF4081'
					/>
				</div>
				{timer ? (
					<div>
						<h4
							className='text-20 font-semibold pt-2 text-center'
							style={{ color: "#FF564C" }}>
							<div>
								<h4
									className='text-20 font-semibold pt-2 text-center'
									style={{ color: "#FF564C" }}>
									{formatTime(timer)}
								</h4>
							</div>
						</h4>
					</div>
				) : undefined}
				<div className='mic-wrapper'>
					{messageData?.length > 0 &&
					messageData[messageData.length - 1].receiver &&
					messageData[messageData.length - 1].type === "success" ? (
						<div className='flex px-2 py-5'>
							<div
								className='audio-player bg-success rounded-[15px] px-3 py-3  flex items-center mx-2 w-1/2 justify-center'
								onClick={createOrderRequest}>
								<h3 className='text-white font-bold text-15'>Accept</h3>
							</div>
							<div
								onClick={cancelHandler}
								className='audio-player rounded-[15px] px-3 py-3  flex items-center mx-2 w-1/2 justify-center'
								style={{
									background: "#FF564C",
								}}>
								<h3 className='text-white font-bold text-15 '>
									Cancel
								</h3>
							</div>
						</div>
					) : (
						<>
							<div className='flex items-center relative py-5 px-4'>
								<div className='flex items-center w-[calc(100%-85px)]'>
									<span className=''>
										<ion-icon
											style={{ "--ionicon-stroke-width": "25px" }}
											name='add-circle'
											color='light'></ion-icon>
									</span>
									<div className='flex items-center relative w-full flex-1 ml-2 baz-ai-text-input'>
										<input
											onKeyPress={(e) => {
												if (e.key === "Enter") {
													// handleComment();
												}
											}}
											onChange={(e) => setComment(e.target.value)}
											value={comment}
											type='text'
											placeholder='Enter your question?'
											// placeholder={`${ekkLanguage.learning.commentAs} `}
											className='py-3 px-3 appearance-none block h-12 mr-1 border rounded-[47px] leading-tight focus:outline-1 bg-white focus:bg-white text-14 w-full'
										/>
										<p className='absolute right-[14px] top-[10px]'>
											<ion-icon
												color='success'
												name='send-outline'></ion-icon>
										</p>
									</div>
								</div>
								<span
									style={
										messageData[messageData.length - 1]?.loading
											? {
													pointerEvents: "none",
													cursor: "not-allowed",
													opacity: 0.5,
											  }
											: {}
									}
									{...getRootProps({ className: "dropzone" })}
									onContextMenu={(e) => e.preventDefault()}
									className='image mx-1'>
									<input {...getInputProps()} />
									<ion-icon
										color='light'
										name='image-outline'></ion-icon>
								</span>
								<span
									onContextMenu={(e) => e.preventDefault()}
									style={
										messageData[messageData.length - 1]?.loading
											? {
													pointerEvents: "none",
													cursor: "not-allowed",
													opacity: 0.5,
											  }
											: {}
									}
									className={`${
										ripple ? "ripple mic ml-1" : "mic ml-1"
									}`}
									onMouseDown={
										!messageData[messageData.length - 1]?.loading
											? handleTouchStart
											: undefined
									}
									onMouseUp={handleTouchEnd}
									onTouchStart={
										!messageData[messageData.length - 1]?.loading
											? handleTouchStart
											: undefined
									}
									onMouseLeave={handleTouchEnd}
									onTouchEnd={handleTouchEnd}>
									<ion-icon name='mic' color='light'></ion-icon>
								</span>

								{/* {timer ? (
									<span
										className='absolute left-[calc(70%)] bg-primary stop'
										onContextMenu={(e) => e.preventDefault()}
										onClick={handleTouchEnd}>
										<h4 className='text-white font-bold'>Stop</h4>
									</span>
								) : (
									""
								)} */}
							</div>

							{/* <p className='text-black-333 font-semibold text-18 text-center mb-7'>
								Press mic and hold to place voice order
							</p> */}
						</>
					)}
				</div>
			</div>
		</div>
	);
}
