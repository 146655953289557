import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import createFilter from "redux-persist-transform-filter";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunk from "redux-thunk";
import rootReducer from "./reducers";

export default function configureAppStore(preloadedState = {}) {
	const saveSubsetFilter = createFilter("auth", ["loginStatus", "data"]);
	const saveSubsetFilter2 = createFilter("user", [
		"location",
		"locationData",
		"data",
		"mapLocation",
		"createUserData",
		"warning",
		"countryInfo",
	]);
	const saveSubsetFilter3 = createFilter("businessSettings", ["data"]);
	const saveSubsetFilter4 = createFilter("product", [
		"data",
		"singleProduct",
		"productPack",
		"singleProductPack",
		"productOffer",
		"singleProductOffer",
	]);
	const saveSubsetFilter5 = createFilter("productSummery", ["productData"]);
	const saveSubsetFilter6 = createFilter("loan", ["remainingInfo"]);
	const saveSubsetFilter7 = createFilter("driver", [
		"driverData",
		"collectorData",
		"collectorDate",
	]);
	const saveSubsetFilter8 = createFilter("order", ["singleOrder", "from"]);
	const saveSubsetFilter9 = createFilter("subscription", ["upgradePlan"]);
	const persistConfig = {
		key: "pwd_seller_auth",
		storage,
		whitelist: [
			"auth",
			"user",
			"businessSettings",
			"product",
			"productSummery",
			"loan",
			"driver",
			"order",
			"subscription",
		],
		blacklist: [""],
		transforms: [
			saveSubsetFilter,
			saveSubsetFilter2,
			saveSubsetFilter3,
			saveSubsetFilter4,
			saveSubsetFilter5,
			saveSubsetFilter6,
			saveSubsetFilter7,
			saveSubsetFilter8,
			saveSubsetFilter9,
		],
	};
	const persistedReducer = persistReducer(persistConfig, rootReducer);
	const store = configureStore({
		reducer: persistedReducer,
		middleware: [thunk],
		preloadedState,
		enhancers: [],
	});

	const persistor = persistStore(store);

	if (process.env.NODE_ENV !== "production" && module.hot) {
		module.hot.accept("./reducers", () =>
			store.replaceReducer(persistedReducer)
		);
	}

	return { store, persistor };
}
