const english = {
	login: {
		contryLabel: "COUNTRY",
		contryPlaceHolder: "",
		phoneLable: "PHONE NUMBER",
		phonePlaceHolder: "PHONE NUMBER",
		validationLabel:
			"Phone number not valid, Please write phone no in english",
		btnLabel: "Continue",
		tokenSuccessLabel: "Your token has been sent to your phone",
		tokenSuccessFailed: "Your token not create yet please try again",
		langChange: "Change language to",
		termsAllow: "Please allow terms and conditions while logging in!",
	},
	password: {
		passwordLabel: "PASSWORD",
		passwordPlaceHolder: "Enter your password",
		forgetPass: "Forgot Password",
		btnLabel: "Login",
		otpSendLabel: "Code has been sent to your mobile",
		backWord: "Go Back",
		passError: "Password is not matched with phone number",
	},
	changePass: {
		passwordLabel: "NEW PASSWORD",
		passwordPlaceHolder: "*******************",
		confirmPassLabel: "CONFIRM PASSWORD",
		confirmPassPlaceHolder: "*******************",
		passNotMatchLabel: "Password does not match",
		btnLabel: "Continue",
		successLabel: "Your password Changed successfully",
		errorLabel: "Your password Changed failed",
		lengthErrorLabel: "Password must be at least 4 characters long",
		notMatchLabel: "Your password and confirm password not match.",
	},
	token: {
		tokenLabel: "TOKEN",
		tokenPlaceHolder: "Enter your token",
		tokenResend: "Resend token",
		tokenSuccessLabel: "Code has been sent to your mobile, type to continue",
		tokenErrorLabel: "Token send failed",
		timerLabel: "Please wait",
		second: "second",
		btnLabel: "Continue",
		tokenNotMatch: "Token not match",
		tokenVarified: "Your token has been verified",
		tokenNotWrite: "Please enter your token",
	},
	registration: {
		nameLabel: "FULL NAME",
		namePlaceholder: "Enter your full name",
		emailLabel: "EMAIL",
		emailPlaceholder: "Enter your email",
		passwordLabel: "PASSWORD",
		passwordPlaceholder: "Enter your password",
		confirmPassLabel: "CONFIRM PASSWORD",
		confirmPassPlaceholder: "Enter your confirm password",
		validationLabel: "Input field must be at least 4 characters long",
		passNotMatch: "Your password and confirm password not match.",
		btnLabel: "Continue",
		successMessage: "Profile Created Successfully",
		errorMessage: "Please check valid input",
		passLengthValidLabel: "Password must be at least 4 characters long",
		passwordLength: "Your password is",
		strong: "Strong",
		medium: "Medium",
		weak: "Weak",
		gender: "GENDER",
		male: "Male",
		female: "Female",
		others: "Others",
		dobLabel: "DATE OF BIRTH",
		day: "Day",
		month: "Month",
		year: "Year",
	},
	letNav: {
		homeMenuLabel: "Home",
		orderMenuLabel: "Sales",
		mySupplierMenuLabel: "My Suppliers",
		businessProfileMenuLabel: "Business Profile Settings",
		dashboardProfileMenuLabel: "Dashboard",
		phoneCall: "Call To Order",
		myEmployeesMenuLabel: "Employees",
		product: "My Product",
		supplier: "Purchase",
		learning: "Learning",
		collectionDeposit: "Collection & Deposit",
	},

	learning: {
		pageTitle: "Learning List",
		learningDetailsTitle: "Learning Details",
		comments: "Comments",
		commentAs: "Write your comment here",
		likedWarning: "You cannot like more than once",
	},
	home: {
		showMoreLabel: "Show More",
		brandLabel: "Brand",
		offerLabel: "New Offer",
		allSuppliersLabel: "Order List",
		nearSuppliersLabel: "Supplier",
		recommendentLabel: "Just For You",
		searchLabel: "Search products",
		todayPurchaseLabel: "Today’s Purchase",
		totalOrderLabel: "Products from",
		totalOrder: "Orders",
		skusLabel: "SKUs",
		tillNow: "Till Now",
		orderDue: "Orders Due",
		brandProductPageTitle: "Brand Products",
		offerProductPageTitle: "Offer Products",
		offerProductNotFound: "Sorry, No offers were found for you.",
		mySupplierNotFound:
			"You don't have any suppliers. Please buy products to add your suppliers here.",
		account: "Account",
		orderNumber: "Order Number",
		amount: "Amount",
		warning: `Kindly note, transactions will be impacted on 1st March 2024 as system is being upgraded.`,
		warningHeading: "Warning",
	},
	profile: {
		pageTitle: "Profile & Settings",
		contactMenuLable: "Contact & Basic Info",
		passwordMenuLable: "Change Password",
		logoutMenuLable: "SignOut",
		changeLanguageMenuLable: "Change Language",
		kycMenuLable: "KYC Information",
		subscriptionPlan: "Subscription Plan",
	},
	changeLanguages: {
		pageTitle: "Select Language",
		btnLabel: "Save",
	},
	contact: {
		pageTitle: "User Information",
		emailLabel: "Email",
		emailPlaceholder: "Enter your email",
		nidLabel: "National Identity Card",
		nidPlaceholder: "Enter National Identity Card number",
		btnLabel: "Save",
		successLabel: "Your profile Updated. successfully",
		currectEmail: "please enter current email address",
	},
	otpsend: {
		pageTitle: "Verify Number",
		btnLabel: "Send",
		message: "Tap to send authentication code to your mobile via SMS.",
		successLabel: "Your token has been sent to your phone",
		errorLabel: "Your token not create yet please try again",
	},
	otpsubmit: {
		pageTitle: "Verify Number",
		btnLabel: "Submit",
		verifiLabel: "Verify & Proceed",
		title: "Verification Code",
		paragraph: "We have sent the verification code to Your Mobile Number",
		otpLabel: "Enter your OTP code",
		otpPlaceholder: "Enter your OTP code",
		receiveYetLabel: "Didn’t receive code?",
		againRequestLabel: "Request again",
		timerLabel: "Please wait",
		second: "second",
		successLabel: "Your token has been sent to your phone",
		errorLabel: "Your token not create yet please try again",
		tokenVarifiedMessage: "Your token has been verified",
		validationLabel: "Please submit your OTP",
		optNotMatch: "Your OTP is not valid",
	},
	changePassword: {
		pageTitle: "Change Password",
		btnLabel: "Submit",
		passwordLabel: "PASSWORD",
		passwordPlaceholder: "Enter your password",
		confirmPassLabel: "CONFIRM PASSWORD",
		confirmPassPlaceholder: "Enter your confirm password",
		successLabel: "Your password Updated.",
		errorLabel: "Your password Updated Failed",
		passNotMatchLabel: "Your password and confirm password not match.",
		changePassSuccessPara: "Your password has been Changed Successfully.",
		changePassContinueBtn: "Continue",
	},
	brandDetails: {
		skusLabel: "SKUs",
		descriptionLabel: "Description",
		productTitleLabel: "This Brand Product",
		pageTitle: "Brand Details",
		sku: "SKU",
		import: "Import",
		edit: "Edit",
		delete: "Delete",
		deleteConformationAsk: "Are you sure you want to delete this brand?",
		request: "Request to show price",
	},
	mysuppliers: {
		pageTitle: "Supplier List",
		skusLabel: "SKUs",
		productTitleLabel: "This Suppliers Product",
		supplierOrderAmount: "Supplier Order Amount",
	},
	createOrder: {
		pageTitle: "Create Order",
		buyerDetailsTitle: "Buyer Details",
		sellerDetailsTitle: "Seller Details",
		deliveryDetailsTitle: "Delivery",
		productItemTitle: "Product Items",
		costSummaryTitle: "Cost Summary",
		paymentDetailsTitle: "Payment Details",
		paymentTermsTitle: "Payment Terms",
		paymentMethodeTitle: "Payment Method",
		moqLabel: "Minimum Order Quantity",
		pcsLabel: "Pcs",
		subtotal: "Subtotal",
		tax: "Tax / Vat",
		discount: "Discount",
		deliveryFee: "Delivery Fee",
		creditFee: "Service Fee",
		total: "Total",
		immediatePayment: "Immediate Payment",
		pickAndPay: "Pick And Pay",
		today: "At Night of Delivery Day",
		threeDays: "Pay in 3 days",
		sevenDays: "Pay in 7 days",
		immediatePaymentText: "No Service Fee applicable for this order.",
		todayPaymentText1: "For today payment, additional Service Fee of ",
		todayPaymentText2: "will be added to this order.",
		threePaymentText1: "For 3 days payment, additional Service Fee of ",
		threePaymentText2: "will be added to this order.",
		sevenPaymentText1: "For 7 days payment, additional Service Fee of ",
		sevenPaymentText2: " will be added to this order.",
		cashOnDelivery: "Cash On Delivery",
		cashOnDeliveryText1: "Pay Cash to",
		cashOnDeliveryText2: "when goods is delivered.",
		cash: "Cash",
		bkashText: "Pay through Bkash",
		nagadText: "Pay through Nagad",
		number: "Number",
		numberWarning:
			"If money is sent to any number other than this number, it will be considered as cancelled",
		cashText: "Pay Cash to",
		bankTransfer: " Bank Transfer",
		bankTransferText1: "Bank Transfer - Pay to",
		bankTransferText2: "bank account with 'Order Number' as reference",
		bankTransferText3: "Bank Name",
		bankTransferText4: "A/C Name",
		bankTransferText5: "A/C Number",
		bankTransferText6: "Route Number",
		bankTransferText7: "Branch",
		btnLabel: "Confirm",
		addMoreProductLabel: "Add More Product",
		selectProductLabel: "Submit",
		cosgressTitle: "Congratulations !",
		cosgressPara: "Your order has been completed.",
		preOrderCongress:
			"Your pre-order has been completed successfully. Our representative will contact you shortly",
		congressCancelText:
			"If there is any problem with the order, cancel it before at 12 pm. You cannot cancel the order after at 12 pm.",
		congressProductLabel: "Continue",
		orderNow: "Order Now",
		alert: "Alert",
		importentMessage: "Important message",
		deliveryDateMessage: "Please Select Delivery Date",
		paymentTermsMessage: "Please Select Payment Term",
		paymentMethodMessage: "Please Select Payment Method",
		backButtonMessage:
			"Your cart have some items, if you leave this page your cart will be empty.",
		okBtnLabel: "OK",
		loader: "Please wait...",
		placeConformationMessage: "Are you sure you want to place this order?",
		lessThenMoq:
			"Items Quantity selected is less then minimum order quantity.",
		all: "All",
		requestDeliveryDate: "Request Delivery Date",
		fsMandatoryMessage: "Please Select Finance Support Amount",
		fsCoupon: "FS Coupon",
		downPayment: "Initial Down Payment",
		fsServiceFee: "Finance Support Service Fee",
		fsPlaceholder: "Enter your need financial support amount",
		fsWarning: "financial support amount is incorrect",
		dpWarning1:
			"Your Financing Support request is being reviewed. Please wait patiently till next working day.",
		dpWarning2:
			"Please note below Penalty if payments defaulted. This is approximate only. Always refer to Order Details page for actual amounts.",
		orderId: "Order Id",
		dueAmount: "Due Amount",
		dueLoanTitle:
			"You must clear previous dues, before a new financial request.",
	},

	selectProduct: {
		pageTitle: "Select Product",
		totalLabel: "Total",
		btnLabel: "Submit",
	},
	orderList: {
		pageTitle: "Create Order",
		tabMenuRecent: "All",
		tabMenuPlaced: "Placed",
		tabMenuAccepted: "Accept",
		tabMenuScDelivered: "Scheduled For Delivery",
		tabMenuPartialDelivered: "Partial Delivered",
		tabMenuDelivered: "Delivered",
		tabMenuReturn: "Return Product",
		tabMenuPartialPaid: "Partial Paid",
		tabMenuPaid: "Paid",
		tabMenuCancel: "Cancel",
		tabMenuDeliveryFailed: "Delivery Failed",
		orderItem: "Order Item",
		seller: "Seller",
		buyer: "Buyer",
		loader: "Please wait...",
		createdBy: "Created By",
		to: "To",
		for: "For",
		notFound: "Not Found",
	},
	orderDetails: {
		createdBy: "Created by",
		placedLabel: "Placed",
		deliveredLabel: "Delivered",
		paidLabel: "Paid",
		orderItemLabel: "Order Item",
		castItemLabel: "Cost Details",
		timelineLabel: "TimeLine",
		subtotal: "Subtotal",
		tax: "Tax / Vat",
		discount: "Discount",
		deliveryFee: "Delivery Fee",
		creditFee: "Service Fee",
		total: "Total",
		paymentSettlementLabel: "Payment Settlement",
		date: "Date",
		amount: "Amount",
		dueDate: "Due Date",
		remark: "Remark",
		collectedBy: "Collected by ",
		paymentDetailsLabel: "Payment Details",
		paymentMethod: "Payment Method",
		paymentTerms: "Payment Terms",
		paymentDueDate: "Payment Due Date",
		totalDue: "Total Due",
		totalPaid: "Total Paid",
		remaining: "Remaining",
		deliveryDetailsLabel: "Delivery Details",
		selectTimeDate: "Select Schedule Delivery Date & Time ",
		selectTime: "Select Delivery Time",
		selectDate: "Select Delivery Date",
		acceptOrder: "Accept Order",
		cancelOrder: "Cancel Order",
		scheduleDelivery: "Schedule for Delivery",
		BuyerName: "Buyer’s Name",
		address: "Address",
		phoneNumber: "Phone Number",
		email: "Email",
		deliveryDate: "Delivery Date",
		downloadInvoices: "Download Invoice",
		receiptDownloads: "Print Receipt",
		cancelConformationAsk: "Are you sure you want to Cancel this Order ?",
		cancelConformation: "You can not cancel this order",
		cancelConformationParagraph: `You can not cancel this order, you can cancel your order only same day.`,
		yesBtn: "Yes",
		noBtn: "No",
		withoutSettelment: "Payments completed information will be shown here.",
		bankInfoLabel: "Bank Account Information for Payment Transfer",
		assignDriver: "Assign Driver",
		assignCollector: "Assign Collector",
		assignedDriver: "Assigned Driver",
		assignedCollector: "Assigned Collector",
		notAssigned: "Not Assigned Yet",
		addDeliveryFee: "Add Delivery Fee",
		addDiscount: "Add Discount",
		feePlaceHolder: "Write Your Delivery Fee Amount",
		discountPlaceHolder: "Write Your Discount Fee Amount",
		requestDeliveryDate: "Request Delivery Date",
		scheduleDeliveryDate: "Schedule Delivery Date",
		scheduleDeliveryTime: "Schedule Delivery Time",
		deliveryFailMessage: "Delivery Failed Message",
		selectCancelOrder: "Select Reason for Cancelling Order",
		multipleTime: "Multiple times delivery failed ",
		productNotAvailable: "Product not available",
		cannotFindAddress: "Cannot Find Address",
		noShop: "Founder address but no shop there ",
		buyerNotAccept: "Buyer not accepting order price ",
		supplierNotGive: "Supplier did not give products",
		other: "Other",
		yes: "Yes",
		no: "No",
		deliveryTimeWarning:
			"You must select a delivery date, otherwise we can't process to schedule for delivery",
		deliveryDateWarning:
			"You must select a delivery time, otherwise we can't process to schedule for delivery",
		deliveryDateTimeWarning: "You must select a delivery date and time",
		orderSearchLabel: "Search Your Order",
		loanCollectionTitle: "Finance Support Settlement",
		transactionType: "Transaction Type",
		gateway: "Payment Gateway",
		accountName: "Account Name",
		accountNumber: "Account Number",
		transactionNumber: "Transaction Number",
		payEkkbaz: "Pay Ekkbaz",
		close: "Close",
		confirmDelivery: "Delivery Complete",
		send: "Send",
		receipt: "Reciept",
		receiptNotProvided: "Reciept Not Provided",
		instantDelivery:
			"Are you sure you want to Delivery Complete for this order?",
		fss: "Finance Support Settlement",
		downPaymentLeft: "Down Payment Left",
		default: "Default",
		first: "1st",
		second: "2nd",
		penaltyFee: "Penalty Fee",
		due: "Due",
		third: "3rd",
		immediatePayment: "Immediate Payment",
		inSettlement: "In Settlement",
		closeSettlement: "Close Settlement",
		approve: "Approve",
		downPayment: "Down Payment",
		sellerPayment: "Seller Payment",
		delivered: "Delivered",
		repaid: "Repaid",
		amountWarning: "The actual amount may vary.",
		penaltyHeader: "Default Penalty Schedule",
	},
	businessCreateName: {
		pageTitle: "Business Name",
		businessName: "Business Name",
		businessNamePlaceholder: "Enter Business Name",
		paragraph:
			"We are 3 steps away to create your business. Lets start with your business name.",
		btnLabel: "Next",
		submit: "Submit",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		errorLabel: "Enter business name!",
		congratulation: "Congratulation",
		congratulationMessage: "Your business created successfully",
		kycInformation:
			"Your kyc information not submitted yet. please submit your kyc information",
		freeTrail: "Start Free Trail",
		kycSubmit: "Submit Your KYC",
		pending:
			"Thanks for submitting KYC. Your KYC verification is in progress, please wait.",
		goBack: "Go Back",
		trialStart: "Congratulations! You have successfully started trial.",
	},
	businessType: {
		pageTitle: "Business Type",
		paragraph: "Lets choose your Business type.",
		fmcg: "FMCG",
		miniMart: "Mini Mart",
		superMart: "Super Mart",
		pharmacy: "Pharmacy",
		cart: "Cart",
		bazaarStore: "Bazaar Store",
		hawkerCenter: "Hawker Center",
		restaurant: "Restaurant",
		hotel: "Hotel",
		cosmeticsStore: "Cosmetics Store",
		nonFmcg: "NON FMCG",
		office: "Office",
		NGO: "NGO",
		entertainmentPlace: "Entertainment Place",
		religiousOrganization: "Religious Organization",
		spa: "Spa",
		salon: "Salon",
		gym: "Gym",
		electronicsStore: "Electronics Store",
		shoeStore: "Shoe Store",
		fashionStore: "Fashion Store",
		laundryStore: "Laundry Store",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		btnLabel: "Next",
		validationLabel: "Please Select a business type",
		producer: "Producer",
		reseller: "Reseller",
	},
	businessLocation: {
		pageTitle: "Business Location",
		paragraph: "Enter your business contact details.",
		address: "Address",
		addressPlaceholder: "Enter your address",
		city: "City",
		cityPlaceholder: "Enter city Name",
		postalCode: "Postal Code",
		postalCodePlaceholder: "Enter postal code",
		email: "Email",
		emailPlaceholder: "Enter your email",
		userName: "User Name",
		userNamePlaceholder: "Enter your user name",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		btnLabel: "DONE",
		successLabel: "Congratulations business created",
		errorLabel: "Business creation failed",
		validationLabel: "Please fill up the address to move.",
		loader: "Please wait...",
		validationCityLabel: "Please fill up the city.",
		validationPostCodeLabel: "Please fill up postal code.",
		locationValidation: "Please pick location from map.",
		validationEmailLabel: "Please fill up your email",
	},
	locationPicker: {
		pageTitle: "Search Store Location",
		btnLabel: "CONFIRM",
		paragraph: `Changes the state of the map's marker to select your business location`,
	},
	location: {
		title: "Enable your Location",
		paragraph:
			"This app requires that location services are turned on your device and for this app. You must enable them is Setting before using this app.",
		btnLabel: "Allow only while using the app",
	},
	businessSelect: {
		pageTitle: "Select Business",
	},
	pwaDownload: {
		header: "Download EkkBaz App",
		paragraph:
			"Download the app and order bulk from your supplier any time from now on.",
		cancelBtn: "Cancel",
		downloadBtn: "Download",
	},
	businessProfile: {
		pageTitle: "Business Profile Settings",
		businessDetailsLabel: "Details",
		registrationNo: "Register Number",
		taxNo: "Tex/Vat Number",
		mobileNo: "Mobile Number",
		officeNo: "Office Number",
		email: "Email",
		website: "Website",
		branchLabel: "Branch List",
		paymentDetailsLabel: "Payment Details",
		bankInfoLabel: "Bank Information",
		bankName: "Bank Name",
		branchName: "Bank Branch",
		branchAddress: "Bank Address",
		swiftCode: "Bank Swift Code",
		acName: "Bank A/C Name",
		acNumber: "Bank A/C Number",
		bkashInfoLabel: "Bkash Information",
		bkashAcName: "Bkash A/C Name",
		bkashAcNo: "Bkash A/C Number",
		nagadInfoLabel: "Nagad Information",
		nagadAcName: "Nagad A/C Name",
		nagadAcNo: "Nagad A/C Number",
		upayInfoLabel: "Upay Information",
		upayAcName: "Upay A/C Name",
		upayAcNo: "Upay A/C Number",
		verified: "Verified",
		unVerified: "Unverified",
		editBusinessMenu: "Edit Business",
		paymentTermsSettings: "Customer Payment Terms Settings",
		addPayMenu: "Add Payment Option ",
		setLocation: "Update Business Location",
		addMenu: "Add Branch",
		verifyBusinessMenu: "Verify Business",
		bankInfoNotFound: "Bank information not found.",
		deleteBusiness: "Delete Business",
		deleteWarning:
			"Your all orders, products, employees, and your entire account will be deleted. Are you sure you want to delete this Business?",
		otpSubmit: "OTP Submit",
		confirmDeleteWarning:
			"Please think again! your all orders, products, employees, and your entire account will be deleted. Are you sure you want to delete this Business?",
		switchBusiness: "Switch Business",
	},
	paymentSettings: {
		pageTitle: "Customer Payment Terms Settings",
		mainTitle: "Select your suitable Payment Terms",
		subTitle:
			"The payment terms you select here, the customer can only using this Payment Terms while ordering.",
		btnLabel: "Save",
		payNow: "Pay Now",
		cashOnDelivery: "Cash On Delivery",
		pickAndPay: "Pick and Pay",
		today: "At Night of Delivery Day",
		threeDays: "Pay in 3 days",
		sevenDays: "Pay in 7 days",
		fourteenDays: "Pay in 14 days",
		thirtyDays: "Pay in 30 days",
	},
	businessEdit: {
		pageTitle: "Edit Business ",
		uploadPhoto: "Upload Photo",
		email: "Email",
		emailPlaceholder: "Enter your email",
		address: "Address",
		addressPlaceholder: "Enter your address",
		city: "City",
		cityPlaceholder: "Enter city Name",
		postalCode: "Postal Code",
		postalCodePlaceholder: "Enter postal code",
		resigter: "Register Number",
		resigterPlaceholder: "Enter your Register Number",
		tex: "Tex/Vat Number",
		texPlaceholder: "Enter your Tex/Vat Number",
		mobile: "Mobile Number",
		mobilePlaceholder: "Enter your Mobile Number",
		office: "Office Number",
		officePlaceholder: "Enter your Office Number",
		website: "Website",
		websitePlaceholder: "Enter your website",
		btnLabel: "Save",
		successLabel: "Your profile Updated. successfully",
		currectEmail: "Please enter current email address",
	},
	bankDetails: {
		referenceNumber: "Reference Number",
		bankName: "Bank Name",
		accountName: "A/C Name",
		accountNumber: "A/C Number",
		routeNumber: "Route Number",
		branch: "Branch",
	},
	invoice: {
		title: "INVOICE",
		receipt: "Receipt",
		buyerDetails: "Buyer Details",
		sellerDetails: "Seller Details",
		description: "DESCRIPTION",
		qty: "QTY",
		price: "PRICE",
		subtotal: "SUBTOTAL",
	},
	verifyBusiness: {
		numberOne: "1",
		numberTwo: "2",
		numberThree: "3",
		numberFour: "4",
		numberFive: "5",
		numberSix: "6",
	},
	receipt: {
		placed: "Placed",
		name: "Name",
		qty: "Qty",
		price: "Price",
		subtotal: "Subtotal",
		tax: "Tax",
		discount: "Discount",
		creditFee: "Service Fee",
		deliveryFee: "Delivery Fee",
		total: "Total",
		paid: "Paid",
		totalDue: "Total Due",
		paymentTerms: "Payment Terms",
		days: "Days",
		paymentMethod: "Payment Method",
		referenceNumber: "Bank Reference Number",
		deliveryDate: "Delivery Date",
		dueDate: "Payment Due Date",
		printTime: "Print Time",
		conditionsCreditFee: "Payment Conditions & Service Fee",
		immediatePayment: "Immediate Payment",
		cashOnDelivery: "Cash On Delivery",
		atNight: "At Night of Delivery Day",
		payInthreeDay: "Pay in 3 Days",
		payInSevenDay: "Pay in 7 Days",
		afterSeven: "After 7 Days (additional for everyday extra)",
	},
	landing: {
		header1: "Your Personal B2B Trading",
		header2: "& Financing Platform",
		header3: "Pay Later! ",
		login: "Log in",
		bangla: "বাংলা",
		english: "English",
		vietnamese: "Tiếng Việt",
		malay: "Malay",
		retail: "Retail",
		manufacture: "Supplier",
		hotline: "Hot line",
		phoneNumber: "Phone Number",
		phoneNumberPlaceHolder: "Enter Phone Number",
		brandHeader: "Brands working with us!",
		fAbout: "Sell products easily in the wholesale market.",
		location: "Location",
		singapore: "Singapore Office",
		bangladesh: "Bangladesh Office",
		bOfficeAddress: "13th Floor, Razzak Plaza, Mog Bazar Moor, Dhaka-1205",
		otherInfo: "Other info",
		partners: "Our Partners",
		news: "News",
		support: "Support",
		corporate: "Corporate",
		refundPolicy: "Refund Policy",
		termsCondition: "Terms Conditions",
		privacyPolicy: "Privacy Policy",
		contactUs: "Contact Us",
		messageUs: "Message Us",
		facebook: "Facebook",
		sales: "Sales",
		media: "Media",
		downloadBtn: "Download App",
		backers: "Our Backers",
		benefits: "Your Benefits",
		discover: "Latest EkkBaz News",
		benefitsOne: "Increase sales",
		benefitsDisOne: "",
		benefitsTwo: " Reach customer easily",
		benefitsDisOneTwo: "",
		benefitsThree: "Increase products visibility",
		benefitsDisOneThree: "",
		benefitsFore: "Digitize your sales process",
		benefitsDisOneFore: "",
		seeMore: "See More",
		downloadApp: "Download the App Now !",
	},
	employees: {
		pageTitle: "Employees",
		createNewEmployees: "Create New Employee",
		searchPlaceHolder: "Search your employee",
		collectorList: "Collector List",
		collectorSearch: "Search collector",
	},
	employeesDetails: {
		pageTitle: "Employees Details",
	},
	createEmployees: {
		pageTitle: "Create New Employee",
		customerNoHolder: "Enter Mobile Number",
		employeeNumber: "Employee Number",
		name: "Name",
		namePlaceholder: "Enter employee name",
		confirmBtn: "Confirm",
		selectEmployees: "Select employee position",
		admin: "Administrator",
		stuff: "Staff",
		orderManager: "Order Manager",
		bookManager: "Book Manager",
		productManager: "Product Manager",
		employeeManager: "Employee Manager",
		nameError: "Please enter employee name",
		roleError: "Please select employee role",
		edit: "Edit",
		delete: "Delete",
		editEmployee: "Edit Employee",
		deleteConformationAsk: "Are you sure you want to delete this employee?",
		updateEmployee: "Update Employee",
	},
	productList: {
		pageTitle: "Product List",
		offerPageTitle: "Offer List",
		searchYourProduct: "Search Product",
		brandList: "Brand List",
		createProduct: "Create Product",
		createPack: "Create Pack",
		createOffer: "Create Offer",
		product: "Product",
		pack: "Pack",
		Offer: "Offer",
		discount: "Discount",
		all: "All",
	},
	productDetails: {
		pageTitle: "Product Details",
		offerStart: "Offer Start Date",
		offerEnd: "Offer End Date",
		moqLabel: "MOQ",
		consumerLabel: "Consumer Price (MRP)",
		profitLabel: "Profit",
		productDetailTitle: "Product Details",
		expireData: "Expire Date",
		manufactureData: "Manufacture Date",
		productWeight: "Product Weight",
		packingType: "Packing Type",
		importedBy: "Imported By",
		manufacturedBy: "Manufactured By",
		country: "Country",
		descriptionLabel: "Description",
		btnLabel: "Order Now",
		inStock: "In stock",
		outStock: "Out of stock",
		description: "Description",
		inventory: "Inventory",
		unpublished: "Unpublished",
		published: "Published",
		edit: "Edit",
		delete: "Delete",
		deleteText: "Are you sure to delete this product?",
		myProfit: "My Profit",
		buyerProfit: "Buyer Profit",
		skuId: "SKU ID",
		preOrderBtn: "Request Pre-Order",
		preOrderQuantity: "Write Pre-Order Quantity",
		searchTram: "Search Tram",
	},
	brandList: {
		pageTitle: "Supplier/Brand List",
		searchBrand: "Search Brand by Name",
		searchProduct: "Search Brand's Product by Name",
		myBrand: "My Brand",
		allBrand: "All Brand",
		createNewBrand: "Create My New Brand",
		importBrand: "Import Other's Brand",
		brandList: "Brand List",
		products: "Products",
		details: "Details",
		distributorCode: "Distributor Code",
	},
	brandDetail: {
		pageTitle: "Brand Details",
		sku: "SKU",
		import: "Import",
		edit: "Edit",
		delete: "Delete",
		deleteConformationAsk: "Are you sure you want to delete this brand?",
	},
	createNewBrand: {
		pageTitle: "Create New Brand",
		uploadNewLogo: "Upload new logo",
		brandName: "Brand Name",
		brandNamePlaceHolder: "please enter your brand name",
		selectCountry: "Select Country",
		description: "Description",
		descriptionPlaceHolder: "please enter your brand description",
		createdBy: "Created by",
		complete: "Complete",
		congratulation: "Congratulations",
		congratulationParagraph: "Create New Brand has been successfully",
		continue: "Continue",
		conformModal: "Are you sure you want to create this brand?",
		importBrandConformations: "Are you sure you want to import this brand?",
		errorMessage: "You can't upload images of more than 1 MB",
		imageFormatError:
			"Invalid Image format. Only JPEG, PNG, and JPG are allowed",
		ownerName: "Brand's legal owner",
		ownerNamePlaceholder: "Enter the name of the legal owner",
		isLegalOwner: "My Company Legally owns this brand",
		ownerNumber: "Phone Number",
		ownerNumberPlaceholder: "please enter your phone number",
	},
	updateBrand: {
		pageTitle: "Brand Update",
		congratulationParagraph: "Brand update has been successfully",
		conformModal: "Are you sure you want to update this brand?",
	},
	createProduct: {
		pageTitle: "Create Product",
		productWarning: "Product Title will be automatically generated here.",
		productPhoto: "Product Photo",
		productPhotoUpload: "Upload Product Photo",
		uploadPhoto: "Upload Photo",
		uploadFromGallery: "Upload From Gallery",
		takePhoto: "Take Photo",
		productName: "Product Name",
		productNamePlaceHolder: "enter your product name",
		description: "Description",
		descriptionPlaceHolder: "enter your product description",
		selectBrand: "Select Brand",
		selectDepartment: "Select department",
		selectCategory: "Select category",
		selectSubCategory: "Select sub category",
		setPrice: "Set Price",
		sizeDetermine: "Size Determine",
		inventoryDetermine: "Inventory Determine",
		manufacturingDate: "Manufacturing Date",
		expireData: "Expire Data",
		ManufacturerInformation: "Manufacturer Information",
		save: "Save",
		publish: "Publish",
		purchasePrice: "Purchase Price",
		purchasePricePlaceHolder: "write purchase price",
		sellingPrice: "Selling Price",
		sellingPricePlaceHolder: "write selling price",
		priceWarning: "Your Price is empty",
		customerPrice: "Customer Price(MRP)",
		customerPricePlaceHolder: "write customer price",
		taxVat: "TAX/VAT",
		moq: "Minimum Order Quantity(MOQ)",
		taxVatPlaceHolder: "write TAX/VAT",
		moqPlaceHolder: "write MOQ Price",
		weight: "Weight",
		grossWeight: "Gross Weight",
		grossWeightPlaceholder: "write gross weight",
		weightPlaceholder: "write weight",
		weightWarning: "weight is empty",
		measurement: "Measurement",
		weightUnits: "Weight Units",
		units: "Units",
		packingType: "Packing Type",
		ok: "OK",
		manufacturedCountry: "Manufactured Country",
		manufacturedBy: "Manufactured By",
		manufacturedByPlaceHolder: "enter Name of Manufacture",
		importedBy: "Imported By",
		importedByPlaceHolder: "enter name of importer",
		distributedBy: "Distributed By",
		distributedByPlaceHolder: "enter name of distorter",
		initialInventory: "Initial Inventory",
		initialInventoryPlaceHolder: "Write initial inventory",
		skuPlaceholder: "Write sku count",
		sku: "SKU",
		empty: "Empty",
		barCode: "Bar Code",
		scan: "Scan",
		width: "Width",
		widthPlaceholder: "Write width",
		height: "Height",
		heightPlaceholder: "Write height",
		length: "Length",
		lengthPlaceholder: "Write length",
		searchDepartment: "Search Department by Name",
		searchCategory: "Search category by Name",
		searchSubCategory: "Search sub category by Name",
		conformModal: "Are you sure you want to create this product?",
		updateConformModal: "Are you sure you want to update this product?",
		saveConformModal: "Are you sure you want to save this product?",
		updateBtn: "Update",
		updateMessage:
			"Product name, price, stock must be available for publishing.",
		publishMessage: "Are you sure you want to publish this product?",
		unPublishMessage: "Are you sure you want to unpublished this product?",
		englishName: "English Name for Search",
		englishNamePlaceholder: "write search name",
		productCreated: "Product created successfully!",
		finalGrossWeight: "Final Delivery Weight",
		grossWeightUnit: "Total Weight Unit",
		productUpdated: "Product Updated successfully!",
	},
	updateProduct: {
		pageTitle: "Product Update",
	},
	createPack: {
		pageTitle: "Create Pack",
		packWarning: "Pack Title will be automatically generated here.",
		packName: "Pack Name",
		packNamePlaceHolder: "Please write you pack name",
		description: "description",
		descriptionPlaceHolder: "Please write description",
		selectProduct: "Selected Product",
		qtyPack: "Quantity in Pack",
		qtyPackPlaceholder: "write quantity in pack",
		conformModal: "Are you sure you want to create this pack?",
		saveConformModal: "Are you sure you want to save this offer?",
		productCreated: "Pack created successfully!",
		productUpdated: "Pack Updated successfully!",
	},
	createOffer: {
		pageTitle: "Create Offer",
		offerWarning: "Offer Title will be automatically generated here.",
		offerName: "Offer Name",
		offerNamePlaceHolder: "Please write you Offer name",
		description: "description",
		descriptionPlaceHolder: "Please write description",
		selectProduct: "Selected Product",
		qtyOffer: "Quantity in Offer",
		qtyOfferPlaceholder: "write quantity in Offer",
		conformModal: "Are you sure you want to create this offer?",
		saveConformModal: "Are you sure you want to save this offer?",
		offerStartDate: " Offer Start Date ",
		offerEndDate: " Offer End Date ",
		selectedItem: "Selected Product",
		totalProductCount: "Total Product",
		totalAmount: "Total Amount",
		spWarnings: "You have to select a product",
		closeButton: "Close",
		offerCreated: "Offer created successfully!",
		offerUpdated: "Offer Updated successfully!",
	},
	updateOffer: {
		pageTitle: "Offer Update",
		conformModal: "Are you sure you want to update this offer?",
	},
	dashboard: {
		statusLable: "Order Status",
		today: "Today",
		yesterday: "Yesterday",
		thisWeek: "This Week",
		thisMonth: "This Month",
		thisYear: "This Year",
		lastWeek: "Last Week",
		lastMonth: "Last Month",
		lastYear: "Last Year",
		custom: "Custom Days",
		selectDay: "Select Day",
		Order: "Order",
		totalOrder: "Total Order",
		totalOrderAmount: "Total Order Amount",
		fsDueAmount: "Finance Support Due",
		placed: "Placed",
		accepted: "Accept",
		scDelivered: "Scheduled For Deli..",
		partialDelivered: "Partial Delivered",
		delivered: "Delivered",
		return: "Return Product",
		partialPaid: "Partial Paid",
		paid: "Paid",
		totalPaid: "Total Paid",
		cancel: "Cancel",
		deliveryFailed: "Delivery Failed",
		orderAmount: "Order Amount",
		totalAmount: "Total Amount",
		collectedAmount: "Collected Amount",
		dueAmount: "Due Amount",
		totalDue: "Total Due",
		directOrder: "Direct Order",
		directOrderAmount: "Direct Order Amo..",
		ekkHeroOrder: "EkkHero Order",
		ekkHeroOrderAmount: "EkkHero Order Am..",
		cancelOrderAmount: "Cancel Order Amo..",
		seeDetails: "See Details",
		period: "Period",
		perviousWeek: "Previous Week",
		nextWeek: "Next Week",
		supplierOrder: "Supplier Order",
		customerOrder: "Customer Order",
		preview: "Preview",
		promoCode: "Promo Code",
		monthlyOrder: "Current Month Order",
		dueDate: "Due Date",
		orderNumber: "Order Number",
	},

	kycVerification: {
		imageWarning: "	You can't upload image more than 10 MB",
		imageUpload: "Upload your image here, or browse",
		verifyUser: "Verify User",
		nidFront: "National Identity Card - Front Side",
		nidBack: "National Identity Card - Back Side",
		uploadNidFront: "Upload your National Identity Card here",
		uploadNidBack: "Upload your National Identity Card here",
		selfie: "Please take your Selfie",
		selfieLabel: "Take Selfie",
		complete: "Upload complete",
		pleaseCheck: "Please Check",
		next: "NEXT",
		button: "button",
		upload: "Please, upload your",
		nid: "NID",
		copy: "copy",
		tradeLicense: "Trade License/Business Registration",
		uploadTradeLicense: "Upload your Trade License or Business Registration",
		proofAddressLabel1: "Proof Address",
		proofAddressLabel2:
			"Utility bill (such as water, electricity, gas or Bank statement)",
		proofAddressPlaceholder: "Upload your Utility bill paper",
		taxLabel: "Taxpayer Identification Number (TIN)",
		taxPlaceholder: "Upload your Taxpayer Identification Number (TIN)",
		brandAgreementLabel: "Brand Agreement or Contract Paper",
		brandAgreementPlaceholder: "Upload your Agreement or Contract paper",
		ebsAgreementPageTitle: "Agreement & Contract Paper",
		ebCompanyTitle: "EkkBaz Pvt. Ltd",
		giveLater: "I will give this later",
		propertyType: "Property Type",
		rented: "Rented",
		own: "Own",
		rentalAgreement: "Rental Agreement/ Deed with Owner",
		ownAgreement: "Ownership Agreement/ Deed",
		rentalDocument: "Rental Agreement Document",
		ownDocument: "Own Property Paper",
		startDate: "Rental Start Date",
		rentPerMonth: "Rent per Month",
		rentPerMonthPlaceholder: "write your shop rent per month",
		select: "Please, select your",
		fillup: "& fill up your",
		amount: "amount",
		click: "Please click",
		submit: "SUBMIT",
		nidNo: "National Identity Card Number",
		nidNoPlaceholder: "please enter your National Identity Card number here",
		tradeLicenseNo: "Trade or Business License Number",
		tradeLicensePlaceholder: "please enter Trade/Business License number",
		tinNumber: "TIN Number",
		tinNumberPlaceholder: "please enter your TIN number",
		brandSelectLabel: "Select Brand",
		brandSelectPlaceholder: "Select your brand",
		termsConditionPageTitle: "EkkBaz Terms & Condition",
		termsLabel: "Terms & Condition",
		privacyLabel: "Privacy Policy",
		refundPolicy: "Refund Policy",
		acceptCheckbox:
			"I acknowledge that I have received, read, and understand the above documents and I agree to all terms.",
	},
	restrict: {
		pageTitle: "Access Restricted",
		warning: `Staff, Collector, and Driver accounts cannot login here
								! change your role or ask admin to login`,
		btnClick: "Click the green button below to go Home",
	},
	notification: {
		pageTitle: "Notification",
		customer: "Customer",
		supplier: "Supplier",
		update: "Update",
		supllierPageTitle: "Notification from Supplier",
	},
	orderSummary: {
		orderProductPageTitle: "Order Product Summary",
		orderProductDetailsPageTitle: "Order Product Summary Details",
		driverProductPageTitle: "Order Driver Product Summary",
		orderDriverProductDrilldown: "Order Driver Product Summary Details",
		driverDeliveryPageTitle: "Order Driver Status Summary",
		deliveryAmountPageTitle: "Order Delivery Amount Summary",
		orderDeliveryAmountDrilldown: "Order Delivery Amount Details",
		buyerSummary: "Buyer Summary",
		buyerSummaryDrillDown: "Buyer Summary Details",
		delayInPayment: "Delay In Payment",
		delayInPaymentDetails: "Delay In Payment Details",
		productTitle: "Product Title",
		qty: "Qty",
		orderNo: "Order No",
		date: "Date",
		status: "Status",
		product: "Product",
		driver: "Driver",
		searchPlaceholder: "Search Driver",
		totalAmount: "Total Amount",
		totalPaid: "Total Paid",
		totalDue: "Total Due",
		numberOfOrders: "Total Orders",
		deliveryFailed: "Delivery Failed",
		gvmAmount: "GMV",
		numberOfFulfillment: "Fulfillment",
		orderCancel: "Order Cancel",
		revenue: "Revenue",
		dueAmount: "Due Amount",
		dayPassed: "Day Passed",
		storeName: "Store Name",
		selectPlaceholder: "Select Date",
		amount: "Amount",
		orderAmount: "Order Amount",
	},
	autoLogOut: {
		pageTitle: "Automatic Logout",
		warningMsg: "Auto logout since this number was used in another Login",
		instrMsg: "Click the green button below to login again",
		btnLabel: "Log in",
	},
	loan: {
		cardTitle: "Request for Finance Support",
		amount: "Amount",
		serviceFee: "Finance Support Service Fee",
		sfWarning:
			"Financial support of up to 50% is available subject to your performance on the platform.",
	},
	loanDetails: {
		cardTitle: "Finance Support",
		requested: "Request Amount",
		approved: "Approved Amount",
		serviceFee: "Service Fee",
		dueDate: "Due Date",
		status: "Status",
		approvedDate: "Approved Date",
		collectedDate: "Collected Date",
		repaidDate: "Repaid Date",
		downPayment: "Down Payment",
	},

	customerOrder: {
		pageTitle: "Customer Order",
		customer: "Customer",
		customerPlaceHolder: "Search customer by name",
		customerOrderAmount: "Customer Order Amount",
	},
	payment: {
		pageTitle: "Pay Ekkbaz",
		bank: "Bank",
		acName: "A/C Name",
		acNumber: "A/C Number",
		branch: "Branch",
		address: "Address",
		city: "City",
		postal: "Postal Code",
		country: "Country",
		selectTimeDate: "Select Time & Date",
		transitionId: "Transaction ID",
		transitionIdPlaceHolder: "enter your Transaction ID",
		amount: "Amount",
		amountPlaceHolder: "enter your sending amount",
		uploadReceipt: "Upload Receipt",
		senderInfo: "Sender Information",
		receiverInfo: "Receiver Information",
		senderWarning: "Please add your bank account first",
		paymentConformationMessage:
			"Are you sure you want to submit this payment?",
		inputWarning: "Please fill up this field",
		congressPayment:
			"Congratulations! Your payment has been submitted successfully.",
		paymentRecieptImage: "Payment Reciept Image",
		receiptOrBank: "Upload Image of Receipt or Bank Slip",
		warning: "Pay amount is incorrect",
	},
	depositList: {
		pageTitle: "Deposit List",
		collectionAndDeposit: "Deposit & Collection",
		depositDetails: "Deposit Details",
		collectionDetails: "Collection Details",
		selectDate: "Select Date",
		selectCollector: "Select Collector",
		txnNo: "TXN No",
		totalCollection: "Total Coll",
		branch: "Branch",
		totalOrder: "Total Order",
		verified: "Verified",
		unVerified: "Unverified",
		collection: "Total Collection",
		totalDeposit: "Total Deposit",
		totalDue: "Total Due",
		bankName: "Bank Name",
		acName: "A/C Name",
		acNumber: "A/C Number",
		routeNumber: "Route Number",
		transectionNumber: "Transaction Number",
		bankAddress: "Bank Address",
		collectorName: "Collector Name",
		comment: "Comment",
		placeVerifyMessage: "Are you sure you want to Verify this Deposit?",
		placeUnverifyMessage: "Are you sure you want to Unverify this Deposit?",
		confirmPageVerifyText: "Your deposit verification has been Successful",
		confirmPageunVerifyText: "Your deposit unverified has been Successful",
		depositAmount: "Deposit Amount",
		collectionAmount: "Collection Amount",
		numberOfCollection: "Number of Collection",
		depositType: "Deposit Type",
	},
	voiceOrder: {
		pageTitle: "Voice Ordering",
		bannerHello: "Hello",
		bannerAI: "Welcome to Voice Ordering AI",
		bannerUse: "You can find your desired product using me",
		speechTitle: "Your Speech",
		speechHeader: "I would like to order - ",
		speechInfo:
			"Please tell me the product name by clicking the above white button",
		keywordTitle: "Matched Keyword",
		keywordInfo: "Please select above one keyword to get related products",
		productsTitle: "Best Matched Product List",
	},
	couponList: {
		pageTitle: "Finance Support Promo",
		addCoupon: "Add Promo Code",
		couponPlaceholder: "enter a promo code",
		add: "Add",
		applyCoupon: "Apply a FS Promo Code",
		apply: "apply",
		expired: "expired",
		used: "used",
		remove: "remove",
		applied: "applied",
		endIn: "End In",
	},
	addBankSettings: {
		bottomSheetTitle: "Add Your Bank Account",
		pageTitle: "Add Your Bank Account",
		accountName: "Account Name",
		accountNamePlaceholder: "Enter your account name",
		accountNumber: "Account Number",
		accountNumberPlaceholder: "Enter your account number",
		selectBank: "Select Bank",
		selectBankPlaceHolder: "Select your bank account",
		branch: "Branch",
		branchPlaceHolder: "Enter your bank branch",
		address: "Address",
		addressPlaceholder: "Enter bank address",
		selectCountry: "Select Country",
		selectCountryPlaceHolder: "Select your bank country",
		selectCity: "Select City",
		selectCityPlaceHolder: "Select city",
		postalCode: "Postal Code",
		postalCodePlaceHolder: "Enter your bank postal code",
		buttonTitle: "Save",
		bankNameWarning: "Please enter your bank account name",
		bankNumberWarning: "Please enter your bank account number",
		selectBankWarning: "Please select a bank",
		branchWarning: "Please enter your bank branch",
		addressWarning: "Please enter your address",
		cityWarning: "Please enter your city",
		searchBank: "Search your bank",
	},
	addedBankList: {
		pageTitle: "Bank List",
		acName: "A/C Name",
		acNumber: "A/C Number",
		branch: "Branch",
		address: "Address",
		city: "City",
		country: "Country",
		addBank: "Add Your Bank Account",
	},
	blackListed: {
		pageTitle: "Blacklisted",
		warningMsg: "Your Business is Blacklisted",
		instrMsg:
			"Please request your business owners to contact EkkBaz for necessary actions to re-enable your business.",
		btnLabel: "Log out",
	},
	ebsAgreement: {
		headline: "Provisional Agreement on Platform License",
	},
	kycInfo: {
		pageTitle: "KYC Information",
		nidFront: "National Identity Card - Front Side",
		nidBack: "National Identity Card - Back Side",
		tradeLicense: "Trade License",
		selfie: "Selfie",
		proofAddress: "Proof of Address",
		tin: "Taxpayer Identification Number (TIN)",
		contractPaper: "Brand Agreement or Contract paper",
		agreementAndContact: "Agreement or Contract paper",
		cheque: "Cheque image",
		name: "Name",
		signature: "Signature",
		notFound: "Not Found",
		viewPdf: "View PDF",
		hidePdf: "Hide PDF",
	},
	customerList: {
		pageTitle: "Distributor List",
		searchPlaceHolder: "Search Your Distributor",
		detailsPageTitle: "Distributor Details",
		customerDetailsLabel: "Distributor Information",
		businessTypeLabel: "Business Type",
		customerNameLabel: "Name",
		addressLabel: "Address",
		postalCodeLabel: "Postal Code",
		cityLabel: "City",
		acceptedCustomerLabel: "Accepted Distributor",
		requestedCustomerLabel: "Request For Price",
		accept: "Accept",
		acceptConfirmation: "Are you sure you want to Accept this Distributor?",
		km: "km",
		createCustomer: "Create New Distributor",
		customerNoHolder: "Distributor Mobile Number",
		nextBtn: "Next",
		deleteDistributor: "Delete Distributor",
		addCustomPrice: "Add Product Custom Price",
		warningMsg: "Are you sure you want to delete this Distributor?",
		yes: "Yes",
		no: "No",
		successMsg: "Distributor deleted successfully!",
		customPriceMsg: "Are you sure want to custom price for this distributor?",
		inputPlaceholder: "Enter Custom Price",
		priceEmptyError: "Custom Price cannot be empty!",
		alreadySetPriceError:
			"This Product price already set for this distributor",
		cancelBtn: "Cancel",
		submit: "Submit",
		modalTitle: "Set Custom Product Price",
		productsSectionTitle: "Customized Price Products",
		updatePriceTitle: "Update Custom Price",
		deletePriceTitle: "Delete This Price Product",
		productDetailTitle: "Product Details",
		offerDetailsTitle: "Offer Details",
		description: "Are you sure you want to delete this custom price product?",
		distributorCode: "Distributor Code",
		notSet: "Not Set Yet",
		distributorCodeAdd: "Add Distributor Code",
		distributorCodeEdit: "Update Distributor Code",
		writeDistributorCode: "Write Distributor Code",
		successLabel: "Distributor Code Added Successfully",
		editSuccessLabel: "Distributor Code Updated Successfully",
	},
	createSupplier: {
		pageTitle: "Create New Supplier",
		invitedSupplierList: "Invited Supplier List",
		searchInvitedSupplierList: "Search invited supplier list",
		customerExists: "Already exists!",
		invited: "Invited",
		newBusiness: "New Business Created Successfully",
		addNameWarning: "Please Add Business Name",
		addCustomerNameWarning: "Please Add Customer Name",
	},
	createBusinessInfo: {
		pageTitle: "Contract Management Info",
		title: "Business Information",
		brandsLabel: "Brand's Name",
		brandsPlaceholder: "Enter Brands Name",
		skusLabel: "Total SKU",
		skusPlaceholder: "SKU amount",
		srsLabel: "Total SR",
		srsPlaceholder: "SR amount",
		driversLabel: "Total Driver",
		driversPlaceholder: "Driver amount",
		customersLabel: "Total Customer",
		customersPlaceholder: "Customer amount",
		salesLabel: "Monthly Sales Amount",
		salesPlaceholder: "Enter Sales Amount",
		fsLabel: "Monthly FS Amount Needed",
		fsPlaceholder: "Enter FS Amount Needed",
		pwFsLabel: "Probable Week for FS Need",
		pwFsPlaceholder: "Select week",
		operationLabel: "Business Operation Start Date/Year",
		operationExtraLabel: "Year",
		operationPlaceholder: "Select Year",
		aeLabel: "AE Feedback",
		aePlaceholder: "Enter AE Feedback",
		nextBtn: "Next",
		owner: "Owner",
		manager: "Manager",
		stuff: "Stuff",
		validationLabel: "Please fill up the address to move.",
		okBtnLabel: "OK",
		successMessage: "Business info created successfully",
		brandError: "Enter brands name",
		skuError: "Enter sku amount",
		srError: "Enter sr amount",
		driverError: "Enter driver amount",
		customerError: "Enter customer amount",
		salesError: "Enter sales amount",
		fsError: "Enter finance support Amount",
		operationError: "Select operations start year",
		feedbackError: "Enter your feedback",
		regionLabel: "District",
		areaLabel: "Sub District/Thana",
		regionError: "Select a region",
		areaError: "Select a area",
		areaPlaceholderWarning: "Please select a region first",
		pwFsError: "Please select a week",
	},
	customerDetails: {
		AEUserLabel: "Account Executive",
		HSuserLabel: "Hub Staff",
		visitType: "Visit Type",
		createdAt: "Created at",
		createdBy: "Created by",
		updatedAt: "Updated at",
		updatedBy: "Updated by",
		businessCardTitle: "Business Information",
		contactCardTitle: "Contact Information",
		contractCardTitle: "Contract Information",
		visitCardTitle: "Visit Information",
		name: "Name",
		number: "Number",
		role: "Role",
		orderType: "Order Type",
		serviceType: "Service Type",
		revenueTier: "Revenue Tier",
		expectedRevenue: "Expected Monthly Revenue",
		expectedCloseDate: "Expected Close Date",
		status: "Status",
		checkIn: "Check in Time",
		checkOut: "Check out Time",
		remark: "Remark",
		location: "Location",
		image: "Image",
		image2: "Check Out Image",
		pageTitle: "Customer Details",
		businessType: "Business Type",
	},
	createContact: {
		pageHeadline: "Contact Information",
		nameLabel: "Name",
		namePlaceholder: "Enter Name",
		numberLabel: "Number",
		numberPlaceholder: "Enter Number",
		roleError: "Select a role",
	},
	createContract: {
		pageHeadline: "Contract Information",
		orderTypeLabel: "Order Type",
		serviceTypeLabel: "Service Type",
		revenueTierLabel: "Revenue Tier",
		statusLabel: "Status",
		selectPlaceholder: "Select",
		monthlyRevenueLabel: "Expected Monthly Revenue",
		monthlyRevenuePlaceholder: "Enter expected monthly revenue",
		expectedCloseDateLabel: "Expected Closed Date",
		expectedCloseDatePlaceholder: "Select date",
		primaryOrder: "Primary",
		secondaryOrder: "Secondary",
		tertiaryOrder: "Tertiary",
		successMessage: "Contract created successfully",
		statusError: "Select a status",
		closeDateError: "Select a close date",
		monthlyRevenueError: "Enter monthly revenue amount",
		revenueTierError: "Select a revenue tier",
		serviceTypeError: "Select a service type",
		orderTypeError: "Select a order type",
	},
	newCustomer: {
		pageTitle: "Create New Business",
		mobileNo: "Mobile Number",
		customerNoHolder: "Business mobile number",
		customerName: "User Name",
		role: "Role",
		selectRole: "Select Role",
		customerNameHolder: "Enter User full name",
		businessPhoto: "Business Photo",
		uploadPhoto: "Upload Business/Store Photo",
		uploadPhotoTitle: "Upload Photo",
		uploadFromGallery: "Upload From Gallery",
		takePhoto: "Take Photo",
		businessName: "Business Name",
		businessNameHolder: "enter business/store name",
		address: "Address",
		addressHolder: "enter business/store address",
		city: "City",
		cityHolder: "enter city",
		postalCode: "Postal Code",
		postalCodeHolder: "enter postal code",
		selectBusiness: "Select Business Type",
		fmcg: "FMCG",
		nonFmcg: "NON-FMCG",
		nextBtn: "Next",
		completeBtn: "Complete",
		customerFindText: `Business is already found here, if you want you, can choose to add it to your customer list.`,
		addBusiness: "Add Business",
		addBusinessInfo: "Do you want to add this business as your customer?",
		noBtn: "No",
		alreayExist: "Business already exists!",
		reseller: "Reseller",
		businessNameWarning: "Please Add Business Name",
		distributorNameWarning: "Please Add User Name",
		businessCreationSuccess: "New Business Created Successfully",
		businessLocationWarning: "Please Add Business Location from Google Map",
	},
	industryType: {
		pageTitle: "Working Industry",
		title: "Please select your working industry",
	},
	addCard: {
		pageTitle: "Add Card",
		addedList: "Added Card List",
		addNewCard: "Add New Card",
		continue: "Continue",
	},
	paymentModule: {
		pageTitle: "Select Upgrade Plans",
		upgradeNow: "Upgrade Now",
		monthlyPlan: "Monthly Plan",
		annualPlan: "Annual Plan",
		card: "Card",
		paymentMethod: "Payment Method",
		continue: "Continue",
		selectedMethod: "Please select a payment method..!",
		checkOut: "Check Out",
		paymentCard: "Payment Card",
		changeCard: "Change Card",
		selectPackage: "Select Package",
		orderSummary: "Order Summary",
		subtotal: "Subtotal",
		total: "Total",
		payNow: "Pay Now",
		successTitle: "Payment Successful",
		successText: "Thankyou for your payment",
		yourPlan: "Your Subscription Plan",
		transactionHistory: "Transaction History",
		cardNumber: "Card Number",
		status: "Status",
		invoice: "Invoice",
		newCard: "Add new card",
	},
};
export default english;
