import { IonApp, IonButton, IonContent, IonPage } from "@ionic/react";
import BazAi from "components/mobile-components/BazAI";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

export default function BazAiPage() {
	const contentRef = useRef();
	const messageData = useSelector((state) => state?.bazAi?.data);

	const scrollToBottom = () => {
		if (contentRef.current) {
			contentRef.current.scrollToBottom();
		}
	};
	useEffect(() => {
		scrollToBottom();
	}, [messageData.length]);

	return (
		<IonApp>
			<IonPage>
				<IonContent ref={contentRef}>
					<BackButtonTitle title='Baz AI' />
					<BazAi />
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
